<template>
  <div>
	<HYList v-bind="listConfig" >
		<template #columnHead="{ row }">
			<div style="padding:10px">
			 {{row.contractNo}}
			</div>
		</template>
		<template #columnFoot="{}">
			<div style="text-align:right">
				<van-button square type="primary" text="查看合同" />
			</div>
		</template>
		<template #rightTool="{ row }">
			<van-button square type="primary" style="height:100%" :text="row.frameNo" />
		</template>
		<template #leftTool>
			<van-button square type="primary" style="height:100%" text="选择left" />
		</template>
		<template #empty>
			<div class="none-tip">
				<van-image
				:src="require('@/assets/images/none_data.jpg')"
				/>
				<p>尊敬的用户，您当前暂无合同信息</p>
			</div>
		</template>
	</HYList>
  </div>
</template>
<script>
import HYList from '@/components/List/index'
import dayjs from 'dayjs'
export default {
	name:'OrderList',
	components: { HYList },
	provide () {
		return {
			pageContext: this.pageContext
		}
	},
	data() {
		return {
			listConfig:{
				api:{
					url:'/afis-carservice/contract/web/list/h5'
				},
				form:{
					customerNames:'搜索条件'
				},
				column:[
					{
						label:'客户名称',
						code:'customerName'
					},
					{
						label:'车架号',
						code:'frameNo'
					},
					{
						label:'服务信息',
						code:'productNames'
					},
					{
						label:'签署日期',
						code:'agreementTime',
						render:(h,rowData)=>{
							const time=rowData.agreementTime && dayjs(rowData.agreementTime).format('YYYY-MM-DD')
							return h('span',{},time)
						}
					}
				]
			}
		}
	},
	computed: {
	},
	async created(){
	},
	mounted() {

	},
	methods:{
		dayjs
	}
}
</script>
<style lang="scss" scoped>
.none-tip {
    position: relative;
    top: 20%;
    text-align: center;
    p {
        margin-top: 10px;
        text-align: center;
    }
}
</style>